<template>
    <div class="presentation section" id="home-presentation"> 

            <div class="presentation-title title">
                Passage du père Noël chez vous
            </div>

            <div class="presentation-text">
                Durant environ quinze minutes, vivez un moment en suspend dans le temps en compagnie de l’homme rouge le plus apprécié sur Terre. Partagez cet instant  unique avec votre famille et vos amis !
            </div>
            <div class="presentation-text">
                Profitez-en pour prendre des photos inoubliables en famille ! Vous pourrez alors vous venter d’avoir vu le vrai père Noël passer chez vous !
            </div>

        </div>
</template>

<script>
    export default {
        name: "HomePresentation"
    }
</script>


<style scoped>
    .presentation-title  {
        color: var(--color-primary-1);
    }

    .presentation-text {
        color: var(--color-primary-1);
        margin-bottom: 2em;
        font-size: 1.25em;
        text-align: justify;
        line-height: 1.8em;
    }
</style>